<template>
  <div id="menu-bar-portal" class="height_100 py-4 d-flex align-content-space-between">
    <div class="top w-100 pt-2">
      <!-- Logo Section -->
      <v-container
          class="d-flex justify-center align-center"
          color="#F3F3EE"
          height="60"
      >
        <v-img
            contain
            max-height="25.94"
            max-width="120"
            plain
            src="../assets/images/UPGPT.png"
        ></v-img>
      </v-container>
      <!-- New Chat Button -->
      <v-container class="d-flex flex-column align-center" style="padding: 24px 0 0 0 ;">
        <v-btn
            class="m-16-10-button mb-6"
            color="#14C6DE"
            outlined
            rounded
            @click="toLanding(); "
        >
          <v-img
              contain
              max-height="16"
              max-width="16"
              src="../assets/images/add_thread.svg"
              style="margin-right: 4px;"
          >
          </v-img>
          <span class="h5-en blue-text ">{{ $t('MenuBarPortal.NEW_THREAD') }}</span>
        </v-btn>
        <!-- Thread list -->
        <v-container v-show="threadHistory.length > 0" class=" add_thread">
          <v-img
              contain
              max-height="20.84"
              max-width="20.5"
              plain
              src="../assets/images/library.svg"
              style="margin-right: 4.29px"
          ></v-img>
          <span class="h4-en">Library</span>
        </v-container>
        <v-container v-show="threadHistory.length > 0" class=" add_thread_lists">
          <v-list class="p-0 custom-list " color="#F3F3EE" elevation="0">
            <v-list-item
                v-for="(item, index) in threadHistory"
                :key="index"
                class="list_items"
                @click="updateseletedThread(index, item)"
            >
              <!-- 左側 Icon -->
              <v-list-item-icon class="m-0">
                <v-img
                    :src="selectedThread === index ? selectedIcon  : defaultIcon"
                    height="36"
                    max-width="1"
                ></v-img>
              </v-list-item-icon>

              <!-- 文字 -->
              <v-list-item-content class="p-0">
                <v-list-item-title
                    :class="{'selected-text': selectedThread === index, 'default-text': selectedThread !== index }"
                >
                  {{ item.content }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-container>
      </v-container>
    </div>

    <div v-if="hideAvatar"></div>
    <div v-else-if="menuMember.userid != ''">
      <div class='d-flex justify-space-between align-center px-4 user_info_box'>
        <v-avatar color="aliceBlue" size="36">
          <span v-if="menuMember.pic === ''">{{ menuMember.nickname }}</span>
          <img v-else :alt="menuMember.name" :src="member.pic"/>
        </v-avatar>
        <div class=" mr-4 ml-1">
                        <span class='p1-en-regular'>
                            {{ menuMember.name }}
                        </span>
        </div>
        <div class="">
          <v-btn v-hide-element='false' icon @click="toSetting()">
            <v-img max-height="24" max-width="24" src="../assets/images/gear.svg"></v-img>
          </v-btn>
        </div>
      </div>
    </div>
    <v-dialog v-if='messageDialog' v-model='messageDialog' content-class='statusDialog' width='360'>
      <message-dialog :message='message' @emitMessageDialog='onEmitMessageDialog'></message-dialog>
    </v-dialog>
  </div>
</template>

<script>

import {mapActions} from 'vuex';
import PersonalSettingService from "@/services/PersonalSettingService";
import passData from "@/services/passData.js";
import i18n from '/common/plugins/vue-i18n.js';
import messageDialog from '@/components/Dialog/messageDialog';
import UpGPTPortalDataServices from "../services/UpGPTPortalDataServices"

export default {
  name: 'MenuBar',
  props: {
    member: {
      type: Object
    }
  },
  components: {
    messageDialog
  },
  data() {
    return {
      upgpt_settingRouter:
          {
            path: '/upgpt/UserSetting/',
            name: i18n.t('SETTING.PERSONALSETTING'),
            normal: 'mdi-account',
            active: 'mdi-account',
            isAdmin: false,
          },
      isMobile: false,
      isToggle: false,
      newPic: null,
      userPic: false,
      newName: null,
      newAgentData: null,
      menuMember: {userid: ''},
      memberData: null,
      statusColor: 'grey',
      messageDialog: false,
      message: '',
      upchat: this.$route.meta.upchat,
      hideAvatar: false,
      isAdmin: window.localStorage.getItem('is_staff'),
      showUpchatDashboard: false,
      hasUpchatLogin: false,
      screen_ratio_class_CIC: "",
      screen_ratio_class: "",
      selectedThread: null,
      threadHistory: [],
      user: JSON.parse(window.localStorage.getItem('user')),
      selectedIcon: require("../assets/images/stroke_selected.png"), // 選中時的圖片
      defaultIcon: require("../assets/images/stroke_non_selected.png"), // 未選中時的圖片
      thread_id: "",
      threadList:[],
      targetThread:[]
    };
  },
  watch: {
    "member": async function () {
      if (this.member.userid != '') {
        this.menuMember = this.member;
        await this.getUserPhoto();
      }
    },
    "newPic": function () {
      this.menuMember.pic = this.newPic
    },
    "newName": function () {
      this.menuMember.name = this.newName.last_name + ' ' + this.newName.first_name;
    },
    "newAgentData": function () {
      this.menuMember.is_service_agent = this.newAgentData.is_service_agent;
      this.menuMember.status = this.newAgentData.status;
    }
  },
  created() {
    if (window.localStorage.getItem('upchat_login') != null)
      this.hasUpchatLogin = window.localStorage.getItem('upchat_login') === 'true' ? true : false;
    this.menuMember = this.member;
    this.menuMember.email = this.user.email;
    let access_token = window.localStorage.getItem('access_token');
    if (access_token != null) {
      this.getUserDetail();
    }
    window.addEventListener("message", (msg) => {
      this.hide(msg.data)
    }, false);
    window.addEventListener('resize', () => {
      this.return_screen_class()
    })
    this.return_screen_class()
    this.getThreadListData()
  },

  mounted() {
    passData.$on("menuBarPic", (e) => {
      this.newPic = e
    })
    passData.$on("menuBarName", (e) => {
      this.newName = e
    })
    passData.$on("menuBarAgentData", (e) => {
      this.newAgentData = e;
    })
    passData.$on("status", () => {
      if (this.menuMember.is_service_agent) {
        this.changeStatus('ONLINE');
      }
    })
  },
  methods: {
    return_screen_class() {
      let ratio = window.innerWidth / window.outerWidth;
      if (ratio <= 0.8) {
        this.screen_ratio_class_CIC = "bg-CIC mb-2 v-list-item-small";
        this.screen_ratio_class = "mb-2 v-list-item-small";
      } else {
        this.screen_ratio_class_CIC = "bg-CIC mb-2";
        this.screen_ratio_class = "mb-2";
      }
    },
    remove_upchat_icon() {
      this.upMenuRouters = this.upMenuRouters.filter(el => el.name != "UpChat" && el.name != i18n.t('UPCHAT.CIC'));
    },
    hide(msg) {
      if (msg == 'hide Avatar') {
        this.hideAvatar = true;
      }
    },
    async getThreadListData() {
      await UpGPTPortalDataServices.getThreadList().then((response) => {
        this.ai_bots_list = response.data;
        // 轉換數據格式
        this.threadHistory = this.ai_bots_list.map(el => ({
          id: el.id,
          content: el.title,  // API 返回的 title 作為 content
          thread_url: "/UpGPT_Portal/" + el.id  // 生成對應的 thread_url
        }))
        this.threadList = response.data.map(el => ({
          id: el.id,
          content: el.title,  // API 返回的 title 作為 content
          thread_url: "/UpGPT_Portal/" + el.id  // 生成對應的 thread_url
        }))
        // this.getselectedThread()
      });
    },
    async getUserDetail() {
      await PersonalSettingService.getCurrentUserDetail()
          .then(response => {
            this.memberData = response.data;
          })
    },
    setLang() {
      let lang = window.localStorage.getItem('language');
      if (lang != 'zh_TW') {
        window.location.reload();
      }
    },
    getUserPhoto() {
      if (this.userPic === false) {
        PersonalSettingService.getCurrentUserDetail()
            .then(response => {
              if (response.data[0].usersetting.photo != null) {
                this.menuMember.pic = response.data[0].usersetting.photo
                const USER = 'user';
                let userJson = window.localStorage.getItem(USER);
                let user = JSON.parse(userJson);
                user.pic = response.data[0].usersetting.photo;
                window.localStorage.setItem(USER, JSON.stringify(user));
                this.userPic = true
              } else {
                this.menuMember.pic = '';
              }
              this.setLang()
            })
      }
    },
    toSetting() {
      window.location.href = ('/upgpt/UserSetting/');
    },
    toLanding() {
      this.selectedThread = null;
      this.$emit("getQuestionDetail", 'insert');
    },
    async onlogout() {
      this.messageDialog = true;
      this.message = i18n.t("GENERAL.LOGOUT_MSG");
      this.message = i18n.t("GENERAL.LOGOUT_MSG");
    },
    ...mapActions([
      'logout',
    ]),
    async changeStatus(newStatus) {
      await this.getUserDetail();

      if (this.memberData != null) {
        this.memberData[0].usersetting.status = newStatus;

        PersonalSettingService.updateDetail(this.memberData[0].id, this.memberData[0])
            .then(response => {
              // set menuMember data to new status
              this.menuMember.status = response.data.usersetting.status;

              // set local storage data to new status
              let userJson = window.localStorage.getItem('user');
              let user = JSON.parse(userJson);
              user.status = response.data.usersetting.status;
              window.localStorage.setItem('user', JSON.stringify(user));
            })
            .catch(response => {
              console.log(response);
            })
      }
    },
    async onEmitMessageDialog(val) {
      this.messageDialog = false;
      if (val) {
        if (this.menuMember.is_service_agent) {
          this.memberData[0].usersetting.status = 'OFFLINE';
          this.memberData[0].usersetting.default_value = JSON.parse(window.localStorage.getItem('user')).default_value
          await PersonalSettingService.updateDetail(this.memberData[0].id, this.memberData[0])
        }
        let tenant = window.localStorage.getItem('tenant');

        await this.logout()
            .then(() => {
              let redirectTo = (tenant != null || tenant != '') ? `/login?tenant=${tenant}&upgpt=Y` : '/login?upgpt=Y';
              this.menuMember = {userid: ''};
              this.userPic = false;
              this.$emit('logoutSuccess');
              this.$router.push(redirectTo);
              this.showUpchatDashboard = false;
              this.remove_upchat_icon();
            })
      }
    },
    updateseletedThread(index, item) {
      this.selectedThread = index;
      this.targetThread = this.threadList.filter(i=> i.id == item.id)
      this.$emit("getQuestionDetail", 'list', this.targetThread);
      // console.log('this.targetThread',this.targetThread)
    },
  }
}

</script>
<style lang="scss" scoped>
@import "../style/portal/typography.scss";
.v-btn__content {
  padding: 0 !important;
}
.custom-list{
  max-height: calc(100vh - 268px);
  overflow-y: auto;
  overflow-x: hidden;
}
.custom-list::-webkit-scrollbar {
  width: 6px; /* 調整滾動條寬度 */
}
.custom-list::-webkit-scrollbar-track {
  background:  #F4F4F4 !important;
}
.custom-list::-webkit-scrollbar-thumb {
  background: rgba(153, 153, 153, 0.3) ;
  border-radius: 10px;

}
</style>